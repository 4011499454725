@media print {
    .print-hidden {
      display: none;
    }
    
    @page {
      margin: 0;
      -webkit-print-color-adjust: exact !important;
    }
  
    .page {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
    .print-no-cutoff {
      page-break-inside: avoid;
    }
    .print-break-before {
      page-break-before: always;
    }
  
  
  
      .print-light-background {
        background-color: rgba(0, 0, 0, 0.03);
      }
  }

  .sidebar {
    position: absolute;
  }
  
  