.form-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
}

.form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    margin: 1rem 0;
}

.button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
}